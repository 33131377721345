<template>
  <v-card>
    <v-card-title class="justify-center mb-2"> Alterar dados </v-card-title>
    <v-card-text>
      <v-text-field
        v-model="data.name"
        class="red--text"
        :append-icon="icons.mdiPencil"
        label="Motivo da reclamação"
        outlined
        dense
      />

      <div class="d-flex justify-end mt-2">
        <v-btn color="error" @click="closeModal()"> Fechar </v-btn>

        <v-btn color="info" class="ml-5" @click="sendModalData()">
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular v-else color="white" indeterminate> </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'
import { mdiPencil } from '@mdi/js'

export default {
  mixins: [formatters, messages],

  props: {
    data: {
      type: [Object, String],
      required: true,
    },
  },

  data() {
    return {
      sectorSelected: '',
      isLoading: false,

      icons: {
        mdiPencil,
      },
    }
  },

  methods: {
    async sendModalData() {
      this.isLoading = true
      const { id, name } = this.data

      const body = {
        name: name.toUpperCase(),
      }

      await axiosIns
        .put(`api/v1/sgq/reason_complaint/update/${id}`, body)
        .then(() => {
          this.showMessage({
            icon: 'success',
            title: 'Sucesso',
            text: 'Dados alterados!',
          })

          this.updatedTable()
        })
        .catch(error => {
          this.showMessage({
            icon: 'error',
            title: 'Erro ao enviar dados!',
            text: error,
          })
        })
        .finally(() => {
          this.isLoading = false
          this.closeModal()
        })
    },
  },
}
</script>
