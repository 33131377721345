import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c(VCard,{staticClass:"mb-6"},[_c(VCardTitle,{staticClass:"d-flex justify-content-between"},[_c('span',[_vm._v(" Tipos de reclamação")]),_c(VSpacer),_c(VBtn,{staticClass:"mr-4",attrs:{"color":"info","data-test":"button-new","outlined":""},on:{"click":function($event){return _vm.openModal('create')}}},[_c(VIcon,{staticClass:"mr-2",attrs:{"size":"25"}},[_vm._v(" "+_vm._s(_vm.icons.mdiPlaylistPlus)+" ")]),_vm._v(" Novo ")],1)],1),_c(VDataTable,{attrs:{"headers":_vm.headers,"items":_vm.itemsTable,"loading":_vm.isLoading,"loading-text":"Carregando dados..."},scopedSlots:_vm._u([{key:"no-data",fn:function(){return [_c('span',[_vm._v("Sem informações para exibir")])]},proxy:true},{key:"item.created_at",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.created_at ? _vm.dateFormat(item.created_at) : '')+" ")]}},{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"info"},on:{"click":function($event){return _vm.openModal('details', item)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiPencil)+" ")])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c(VIcon,{staticClass:"me-2",attrs:{"medium":"","color":"error"},on:{"click":function($event){return _vm.deleteRegister(_vm.endpointDelete, item.id)}}},[_vm._v(" "+_vm._s(_vm.icons.mdiDelete)+" ")])]}}])})],1),_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.showAdminModal),callback:function ($$v) {_vm.showAdminModal=$$v},expression:"showAdminModal"}},[_c('AdminModal',{on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showAdminModal = false}}})],1),_c(VDialog,{attrs:{"width":"500px"},model:{value:(_vm.showAdminDetails),callback:function ($$v) {_vm.showAdminDetails=$$v},expression:"showAdminDetails"}},[_c('AdminDetails',{attrs:{"data":_vm.registrationDetails},on:{"updatedTable":function($event){return _vm.updatedTable()},"close":function($event){_vm.showAdminDetails = false}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }