<template>
  <div>
    <v-card class="mb-6">
      <v-card-title class="d-flex justify-content-between">
        <span> Tipos de reclamação</span>

        <v-spacer></v-spacer>

        <v-btn class="mr-4" color="info" data-test="button-new" outlined @click="openModal('create')">
          <v-icon size="25" class="mr-2">
            {{ icons.mdiPlaylistPlus }}
          </v-icon>
          Novo
        </v-btn>
      </v-card-title>

      <v-data-table :headers="headers" :items="itemsTable" :loading="isLoading" loading-text="Carregando dados...">
        <template v-slot:no-data>
          <span>Sem informações para exibir</span>
        </template>

        <template v-slot:item.created_at="{ item }">
          {{ item.created_at ? dateFormat(item.created_at) : '' }}
        </template>

        <template v-slot:item.edit="{ item }">
          <v-icon medium class="me-2" color="info" @click="openModal('details', item)">
            {{ icons.mdiPencil }}
          </v-icon>
        </template>

        <template v-slot:item.delete="{ item }">
          <v-icon medium class="me-2" color="error" @click="deleteRegister(endpointDelete, item.id)">
            {{ icons.mdiDelete }}
          </v-icon>
        </template>
      </v-data-table>
    </v-card>

    <v-dialog v-model="showAdminModal" width="500px">
      <AdminModal @updatedTable="updatedTable()" @close="showAdminModal = false"></AdminModal>
    </v-dialog>

    <v-dialog v-model="showAdminDetails" width="500px">
      <AdminDetails
        :data="registrationDetails"
        @updatedTable="updatedTable()"
        @close="showAdminDetails = false"
      ></AdminDetails>
    </v-dialog>
  </div>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import { mdiDelete, mdiPencil, mdiPlaylistPlus } from '@mdi/js'
import AdminDetails from './AdminDetails.vue'
import AdminModal from './AdminModal.vue'

export default {
  components: {
    AdminModal,
    AdminDetails,
  },

  mixins: [formatters],

  data() {
    return {
      registrationDetails: {},
      endpointDelete: 'api/v1/sgq/reason_complaint/destroy',

      headers: [
        {
          text: 'MOTIVO DA RECLAMAÇÃO',
          value: 'name',
          sortable: false,
          align: 'left',
        },
        {
          text: 'CRIADO EM',
          value: 'created_at',
          sortable: false,
          align: 'center',
        },
        {
          text: 'EDITAR',
          value: 'edit',
          sortable: false,
          align: 'center',
        },
        {
          text: 'DELETAR',
          value: 'delete',
          sortable: false,
          align: 'center',
        },
      ],

      itemsTable: [],
      isLoading: false,
      showAdminModal: false,
      showAdminDetails: false,

      icons: {
        mdiPencil,
        mdiDelete,
        mdiPlaylistPlus,
      },
    }
  },

  created() {
    this.getItemsTable()
  },

  methods: {
    async getItemsTable() {
      this.isLoading = true

      await axiosIns
        .get('/api/v1/sgq/reason_complaint/index')
        .then(response => {
          this.itemsTable = response.data.data
          this.isLoading = false
        })
        .catch(error =>
          this.showMessage({ title: 'Contate a equipe de desenvolvimento', text: error.message, color: 'error' }),
        )
    },

    updatedTable() {
      this.getItemsTable()
    },

    /**
     * Opens a modal based on the input modalType by setting the corresponding
     * boolean variable to true. Also sets the registrationDetails property
     * based on the input modalData.
     *
     * @param {string} modalType - the type of modal to open
     * @param {object} modalData - optional data to pass to the modal
     */
    openModal(modalType, modalData = {}) {
      const modal = { create: () => (this.showAdminModal = true), details: () => (this.showAdminDetails = true) }

      if (modal.hasOwnProperty(modalType)) {
        modal[modalType]()
        this.registrationDetails = modalData
      }
    },
  },
}
</script>
