<template>
  <v-card>
    <v-card-title class="justify-center mb-2">
      Cadastrar novo tipo
    </v-card-title>

    <v-card-text>
      <v-form ref="form">
        <v-text-field
          v-model="reason"
          :rules="[rules.required]"
          label="Motivo da reclamação"
          outlined
          dense
        />
      </v-form>

      <div class="d-flex justify-end mt-2">
        <v-btn
          color="error"
          @click="closeModal()"
        >
          Fechar
        </v-btn>

        <v-btn
          color="info"
          class="ml-5"
          @click="sendModalData()"
        >
          <span v-if="!isLoading">Enviar</span>
          <v-progress-circular
            v-else
            color="white"
            indeterminate
          >
          </v-progress-circular>
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script>
import axiosIns from '@/plugins/axios'
import formatters from '@/plugins/formattersMixin1'
import messages from '@/plugins/showMessageMixin'

export default {
  mixins: [formatters, messages],

  data() {
    return {
      reason: '',
      isLoading: false,
    }
  },

  methods: {
    async sendModalData() {
      const formIsValid = this.$refs.form.validate()
      const body = { name: this.reason.toUpperCase() }

      if (formIsValid) {
        this.isLoading = true

        await axiosIns
          .post('/api/v1/sgq/reason_complaint/store', body)
          .then(() => {
            this.showMessage({
              icon: 'success',
              title: 'Sucesso!',
              text: 'Sua resposta enviada com sucesso!',
            })

            this.updatedTable()
          })
          .catch(error => {
            this.showMessage({
              icon: 'error',
              title: 'Erro ao enviar dados!',
              text: error,
            })
          })
          .finally(() => {
            this.isLoading = false
            this.closeModal()
            this.resetForm()
          })

        return
      }

      this.showMessage({ title: 'Campo vazio', text: 'Por favor, preencha todos os campos' })
    },

    resetForm() {
      const { form } = this.$refs
      form.reset()
    },
  },
}
</script>
